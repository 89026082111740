<template>
  <div>
    <sidebar-item
      id="dashboards"
      iconClass="ni ni-compass-04 text-[#154462]"
      text="Dashboard"
      link="/dashboard"
      :children="[]"
      :isParent="false"
    />
    <!-- <sidebar-item
      id="Highlights"
      iconClass="ni ni-collection text-[#154462]"
      text="Highlights"
      link="/highlight/highlight_list"
      :children="[]"
      :isParent="false"
    /> -->
    <sidebar-item
      v-if="$store.getters.getUserGroup == 'customer' || $store.getters.getUserGroup == 'premium'"
      id="products"
      iconClass="ni ni-collection text-[#154462]"
      text="Products"
      link="/products"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
      id="cashflow"
      iconClass="ni ni-align-left-2 text-[#154462]"
      text="Cashflow Summary"
      link="/cashflow/list"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
      v-if="$store.getters.getUserGroup == 'superadmin' || $store.getters.getUserGroup == 'premium'"
      id="logslist"
      iconClass="ni ni-books text-[#154462]"
      text="Logs"
      link="/logs/list"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
      v-if="$store.getters.getUserGroup == 'superadmin'"
      id="bankslist"
      iconClass="ni ni-shop text-[#154462]"
      text="Banks"
      link="/banks/list"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
      v-if="$store.getters.getUserGroup == 'superadmin'"
      id="catalogs"
      iconClass="ni ni-diamond text-[#154462]"
      text="Catalogs"
      link="/catalogs/catalog_list"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
      v-if="$store.getters.getUserGroup == 'superadmin'"
      id="Fields"
      iconClass="ni ni-atom text-[#154462]"
      text="Fields"
      link="/fields/fields_list"
      :children="[]"
      :isParent="false"
    />

    <sidebar-item
      id="banks"
      iconClass="ni ni-collection text-[#154462]"
      text="Banks"
      link="/coming_soon"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
    v-if="$store.getters.getUserGroup == 'premium'"
      id="Reporting"
      iconClass="ni ni-collection text-[#154462]"
      text="Upload CSV"
      link="/report/export/csv"
      :children="[]"
      :isParent="false"
    />
    <sidebar-item
      id="Charts"
      iconClass="ni ni-chart-bar-32 text-[#154462]"
      text="Charts"
      link="/coming_soon"
      :children="[]"
      :isParent="false"
    />

  </div>

  <!-- to check the permission and the role add this rine in the sidebar item attributes -->
  <!-- v-if="$guards.has_perm('store.view_service') && !$guards.is_super_admin()" -->
</template>

<script>
import SideBarItem from "../../components/SidebarItem";

export default {
  components: {
    "sidebar-item": SideBarItem,
  },
  data() {
    return {
      userGroup: this.$store.getters.getUserGroup,
      userId: this.$store.getters.getUserId,
    };
  },
  created() {

  },
};
</script>

<style></style>
